.navbar {
  top: 0;
  left: 0;
  right: 0; /* Altura de la barra */
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  padding: 0 2%; /* Espaciado interno */
  z-index: 1000; /* Posición z */
  align-items: center;
  justify-items: center;
  background-color: var(--primary-color);
}

.navbar__fixed {
  position: fixed;
  background-color: transparent;
}

.nav__logo {
  width: auto;
  display: flex;
  align-items: center;
}

.nav__logo:hover {
  cursor: pointer;
}

.nav__logo img {
  height: 1.8em;
  width: 2em;
}

.nav__logo,
.nav__links,
.nav__languages {
  background-color: var(--primary-color);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  top: 0;
  z-index: 100;
  height: 3rem;
  width: auto;
  max-width: fit-content;
  padding: 0 10%;
}

.nav__links {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tertiary-color);
}

.nav__link {
  font-size: 1.2em;
  padding: 0 5%;
  transition: all 0.3s ease;
  cursor: pointer;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
}

.nav__link:visited, .nav__link:link {
  color: var(--tertiary-color);
}

.nav__link:hover {
  color: var(--secondary-color);
}

.nav__languages {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tertiary-color);
  font-weight: bold;
}

.nav__language  a{
  font-size: 1.2em;
  padding: 0 10%;
  transition: all 0.3s ease;
  cursor: pointer;
}

.nav__language a {
  color: var(--tertiary-color);
  text-decoration: none;
}

.nav__language a:visited, .nav__language a:link {
  color: var(--tertiary-color);
}


.nav__language a:hover {
  color: var(--secondary-color);
}


@media (max-width: 768px) {
  .nav__links{
    visibility: hidden;
  }

  .nav__languages{
    padding: 0 15%;
  }
}

