.Home__footer {
    background-color: var(--footer-color);
    color: var(--tertiary-color);
    padding: 2vh 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8em;
    font-weight: 300;
    height: 10rem;
  }
  
  .Home__footer a {
    color: var(--tertiary-color);
    text-decoration: none;
  }
  
  .Home__footer a:hover {
    color: #00baf4;
    cursor: pointer;
  }
  
  .Home__footer__logo {
    height: 2em;
    width: 2.2em;
  }
  
  .Home__footer__projects {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
  }
  
  .Home__footer__projects a,
  .Home__footer__bottom__social a {
    margin: 0 1em;
  }
  
  .Home__footer__separator {
    border-top: 1px solid #afafb2;
    border-radius: 5px;
    width: 100%;
    height: 1px;
  }
  
  .Home__footer__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1em;
  }
  
  .Home__footer__bottom__social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: large;
  }