html{
  scroll-behavior: smooth;
  display:flex;
  flex-direction:column;
}

:root {
  --primary-color: #3a3939;
  --secondary-color: #fca311;
  --tertiary-color: #fff;
  --quaternary-color: #ea5e29;
  --footer-color: #0a0e17;
}

.border-bottom {
  border-bottom: #fca311 2px solid;
}

.bold {
  font-weight: bold;
}
