.Home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(230, 230, 230);
}

.Home__header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em 5%;
  background-color: var(--primary-color);
  color: var(--tertiary-color);
}

.Home__header__text {
  font-weight: bold;
  text-align: center;
  padding: 2rem;
  border-radius: 5px;
}

.Home__header__text h1 {
  font-size: 2em;
  color: var(--secondary-color);
}

.Home__banner {
  background-color: var(--quaternary-color);
  color: var(--tertiary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 5%;
  height: 25rem;
}

.Home__banner button {
  background-color: var(--secondary-color);
  color: var(--tertiary-color);
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 1rem 0;
}

.Home__banner button:hover {
  background-color: var(--tertiary-color);
  color: var(--primary-color);
}

.Home__banner__options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 4em 0;
}

.Home__banner__options p {
  display: flex;
  align-items: center;
}

.Home__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2%;
}

.border-bottom {
  border-bottom: #fca311 2px solid;
}

.Home__content__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30rem;
  color: var(---primary-color);
  width: 100%;
  position: relative;
}

.Home__content__header__banner__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
.Home__content__header__banner__overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.Home__content__header h1 {
  font-size: 2em;
  font-weight: bold;
}

.Home__content__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Home__content__body__services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.services__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  height: 12rem;
  background-color: var(--primary-color);
  color: var(--tertiary-color);
  border-radius: 5px;
  margin: 1rem;
}

.services__card__header {
  width: 100%;
  background-color: var(--secondary-color);
  align-items: center;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.services__card__body {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1.5px solid rgba(209, 213, 219, 0.3);
  height: 100%;
  text-align: center;
}

.aboutUs__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 1200px;
}

.aboutUs__content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.aboutUs__text,
.aboutUs__title,
.aboutUs__image {
  z-index: 2;
}

#sw-js-blob-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.aboutUs__title h1 {
  font-size: 2.5em;
  font-weight: bold;
  margin: 0;
  color: var(--tertiary-color);
}

.aboutUs__text {
  text-align: right;
  padding-left: 5rem;
}

.aboutUs__text p {
  font-size: 1em;
  margin-top: 0.5em;
}

/* Estilos para la imagen */
.aboutUs__image {
  width: 100%; /* Asegúrate de que la imagen no exceda el ancho del contenedor */
  max-width: 600px; /* Limita el tamaño de la imagen para mantenerla proporcional */
  overflow: hidden; /* Esconde cualquier parte de la imagen que exceda el tamaño del contenedor */
  border-radius: 10px; /* Bordes redondeados para la imagen */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15); /* Sombra sutil para dar profundidad */
}

.aboutUs__image img {
  width: 100%; /* Asegura que la imagen se ajuste al contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  transition: transform 0.3s ease-in-out; /* Animación suave para el zoom */
}

.aboutUs__image img:hover {
  transform: scale(1.05); /* Efecto de zoom al pasar el cursor */
}

.Home__content__body__banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20rem;
  color: var(---primary-color);
  width: 100%;
}
.Home__content__body__banner h1 {
  margin:  0;
}

.Home__content__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.Home__content__body__form {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  border-radius: 5px;
  padding: 20px 2%;
  width: 100%;
  margin: 4rem 0;
}

.form-group {
  margin-bottom: 15px;
}

.form-group.message {
  margin-top: 40px;
}

.form-group.contact-button {
  margin-bottom: 0;
  text-align: right;
}

.form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #666;
  color: #ddd;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
  transition: border-color 0.2s;
}

.form-control::placeholder {
  color: #666;
}

.form-button {
  background: none;
  border: none;
  color: #fdc029;
  font-size: 20px;
  cursor: pointer;
  outline: none;
}

.form-button:hover {
  color: #df861d;
}

.body__space {
  padding: 10vh 1%;
}

/* Media queries para responsividad */
@media (max-width: 768px) {
  .aboutUs__content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .aboutUs__content svg {
    display: none;
  }

  #sw-js-blob-svg {
    width: 120%;
    height: auto;
  }

  .aboutUs__title h1{
    color: var(--primary-color);
  }
}
